import axios from 'axios'
import {Check} from '@/entities/Check'
import {PlausibilityCheck} from '@/entities/PlausibilityCheck'

export class CheckFactory {
    static async createChecks(company, countries) {
        countries = countries || await CheckFactory.fetchCountries()
        return [new Check(company, {countries})]
    }

    static async createPlausibilityCheck(company, countries) {
        countries = countries || await CheckFactory.fetchCountries()
        // return Object.keys(company.checks).map(checkDay => new PlausibilityCheck(company, company.checks[checkDay], {countries}))
        return new PlausibilityCheck(company, company.checks, {countries})
    }

    static async fetchCountries() {
        try {
            const {data: countries} = await axios.get(`${process.env.VUE_APP_API_URL}/country`)
            return countries
        } catch (e) {
            console.error(`CheckFactory: Cannot fetch countries`)
            console.error(e)
            return []
        }
    }
}
