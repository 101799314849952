import moment from 'moment'
import {getColorBySector, getTooltipPercentFormatted} from '@/util/charts'
import {getSectorSortIndex} from '@/util/trends.util'

export function getCheckSectorLineChart(check) {
    const tooltipFormatter = getTooltipPercentFormatted()

    const {prevChecks} = check

    let lineChart

    if (!prevChecks || prevChecks?.all?.length < 1) {
        return null
    }

    const groupBy = 'D.MMM'
    let datasets = {}
    let labels = check.prevChecks.all.map(i => moment(i.createdAt).format(groupBy))

    if (labels.length) {
        labels.push(moment(check.createdAt).format(groupBy))
    }

    check.prevChecks.all.map(j => {
        Object.keys(j.sectors).map(key => {
            const s = j.sectors[key]
            if (!datasets[key]) {
                datasets[key] = {
                    key,
                    sort: getSectorSortIndex(key),
                    label: s.name,
                    tension: 0.4,
                    borderWidth: 3,
                    pointRadius: 0,
                    borderColor: s.color,
                    fill: true,
                    data: [],
                    tooltip: tooltipFormatter,
                    maxBarThickness: 6,
                }
            }

            datasets[key].data.push(parseFloat(s.resultInPercents.toFixed(2)))
        })
    })

    Object.keys(check.sectors).map(key => {
        const s = check.sectors[key]
        datasets[key].data.push(parseFloat(s.resultInPercents.toFixed(2)))
    })

    // Adding special chart lines
    datasets.market = {
        key: 'market',
        sort: 98,
        label: 'Market',
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 0,
        borderColor: getColorBySector('market'),
        fill: true,
        data: [57, 56, 56, 57],
        borderDash: [10, 15],
        tooltip: tooltipFormatter,
        maxBarThickness: 6,
    }

    datasets.bloomberg = {
        key: 'bloomberg',
        sort: 99,
        label: 'Bloomberg',
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 0,
        borderColor: getColorBySector('bloomberg'),
        fill: true,
        data: [54, 55, 54, 55],
        borderDash: [10, 15],
        tooltip: tooltipFormatter,
        maxBarThickness: 6,
    }

    return {
        labels,
        datasets: Object.keys(datasets)
            .map(sector => datasets[sector])
            .sort((a, b) => a.sort - b.sort)
    }
}
