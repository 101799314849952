import {sortBy} from 'lodash'
import {getColorBySector} from '@/util/charts'
import {webalize} from 'webalize'
import moment from 'moment'
import {getCheckSectorLineChart} from '@/util/check.charts.util'
import {getSectorSortIndex} from '@/util/trends.util'
import {Bing} from '@/entities/Bing'
import {MIN_SENTIMENT_SCORE} from '@/util/min-sentiment-score'

const ARTICLES_WITH_WARNING_LIMIT_IN_PERCENTS = MIN_SENTIMENT_SCORE

export class PlausibilityCheck {
    constructor(company, checks, options) {
        if (!checks.length) {
            return null
        }

        checks = checks
            .map(check => {
                // if (check.company_name === '4 Boxer Parent Co Inc') {
                //     console.log(check)
                // }
                return {
                    ...check,
                    _id: check._id,
                    area: check.area,
                    subArea: check.sub_area,
                    subSubArea: check.sub_sub_area,
                    resultInPercents: check.avg_sentiment_score * 100,
                    bings: check.bings ? sortBy(
                        check.bings
                            .map(bing => new Bing(bing, check, company.company_name, options))
                            .filter(bing => !bing.releasedLongAgo)
                        ,
                        'resultInPercents'
                    ) : []
                }
            })

        // Calc average sentiment score in each sector
        const sortedByResult = sortBy(checks, 'resultInPercents')

        const allBings = sortBy(checks.reduce((bings, i) => [...bings, ...i.bings], []), 'resultInPercents')

        // Group by areas
        const groupedChecks = sortedByResult.reduce((all, i) => {
            const sector = webalize(i.area)
            const subArea = webalize(i.subArea)
            const subSubArea = webalize(i.subSubArea)

            if (!all[sector]) {
                all[sector] = {
                    name: i.area,
                    color: getColorBySector(sector),
                    groups: {},
                    resultInPercents: []
                }
            }

            if (!all[sector].groups[subArea]) {
                all[sector].groups[subArea] = {
                    name: i.subArea,
                    groups: {},
                    resultInPercents: []
                }
            }

            if (!all[sector].groups[subArea].groups[subSubArea]) {
                all[sector].resultInPercents.push(i.resultInPercents)
                all[sector].groups[subArea].resultInPercents.push(i.resultInPercents)

                all[sector].groups[subArea].groups[subSubArea] = {
                    name: i.subSubArea,
                    resultInPercents: i.resultInPercents,
                    bings: i.bings
                }
            }

            return all
        }, {})

        this._id = checks[0].created_on
        this.id = this._id
        this.createdAt = moment(checks[0].created_on).toISOString()
        this.createdAtDay = moment(checks[0].created_on).format('DD. MM. YYYY')
        this.name = company.company_name
        this.checks = sortedByResult
        this.bings = sortBy(
            allBings, [
                item => this.sortBingsByOrgName(item, company.company_name),
                'resultInPercents'
            ])
        this.warnings = sortBy(
            allBings.filter(i => i.resultInPercents <= MIN_SENTIMENT_SCORE),
            [
                item => this.sortBingsByOrgName(item, company.company_name),
                'resultInPercents'
            ]
        ) || []
        this.resultInPercents = (sortedByResult.reduce((total, i) => total + i.resultInPercents, 0)) / sortedByResult.length
        this.sectors = groupedChecks
        this.calcSentimentScore()
        return this
    }

    calcSentimentScore() {
        Object.keys(this.sectors).map(group => {
            this.sectors[group].resultInPercents = this.sectors[group].resultInPercents
                .reduce((total, j) => total + j, 0) / this.sectors[group].resultInPercents.length

            Object.keys(this.sectors[group].groups).map(subGroup => {
                this.sectors[group].groups[subGroup].resultInPercents = this.sectors[group].groups[subGroup].resultInPercents
                    .reduce((total, j) => total + j, 0) / this.sectors[group].groups[subGroup].resultInPercents.length
            })
        })
    }

    calcTrends() {
        this.trends = Object.keys(this.sectors)
            .map(j => {
                return {
                    ...this.sectors[j],
                    sort: getSectorSortIndex(j),
                    resultInPercents: this.sectors[j].resultInPercents.toFixed(2)
                }
            })
            .sort((a, b) => a.sort - b.sort)
    }

    calcPrevChecks(checks) {
        const findByPrevDates = () => {
            const targetDate = new Date(this.createdAt)
            const previousDates = checks.filter(i => i.name === this.name && ((targetDate - new Date(i.createdAt)) > 0))
            const sortedPreviousDates = previousDates.sort((a, b) => new Date(a) - new Date(b))
            return {
                closest: sortedPreviousDates[0] || null,
                all: sortedPreviousDates
            }
        }

        this.prevChecks = findByPrevDates()

        for (const key in this.sectors) {
            const sector = this.sectors[key]
            const prevSector = this.prevChecks.closest?.sectors[key] || null

            if (prevSector) {
                sector.trend = sector.resultInPercents > prevSector.resultInPercents ? 'up' : 'down'
                sector.diff = (sector.resultInPercents > prevSector.resultInPercents ? sector.resultInPercents - prevSector.resultInPercents : prevSector.resultInPercents - sector.resultInPercents).toFixed(2)

                for (const subAreaKey in sector.groups) {
                    const subArea = sector.groups[subAreaKey]
                    const prevSubArea = prevSector.groups[subAreaKey] || null
                    if (prevSubArea) {
                        subArea.trend = subArea.resultInPercents > prevSubArea.resultInPercents ? 'up' : 'down'
                        subArea.diff = (subArea.resultInPercents > prevSubArea.resultInPercents ? sector.resultInPercents - prevSubArea.resultInPercents : prevSubArea.resultInPercents - subArea.resultInPercents).toFixed(2)

                        for (const subSubAreaKey in subArea.groups) {
                            const subSubArea = subArea.groups[subSubAreaKey]
                            const prevSubSubArea = prevSubArea.groups[subSubAreaKey] || null
                            if (prevSubSubArea) {
                                subSubArea.trend = subSubArea.resultInPercents > prevSubSubArea.resultInPercents ? 'up' : 'down'
                                subSubArea.diff = (subSubArea.resultInPercents > prevSubSubArea.resultInPercents ? sector.resultInPercents - prevSubSubArea.resultInPercents : prevSubSubArea.resultInPercents - subSubArea.resultInPercents).toFixed(2)
                            }
                        }
                    }
                }
            }
        }

        this.calcTrends()

        return this
    }

    setSectorProgressLineChart() {
        this.lineChart = getCheckSectorLineChart(this)
        return this
    }

    sortBingsByOrgName(bing, companyName) {
        const org = bing.entities?.find(i => i.key === 'org')
        if (org) {
            const isCompanyNameInOrgEntity = org.value.find(x => x.toLowerCase().indexOf(companyName.toLowerCase()) >= 0)
            return isCompanyNameInOrgEntity
        }
        return undefined
    }
}
