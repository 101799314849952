import {getColorBySector} from '@/util/charts'
import {webalize} from 'webalize'
import moment from 'moment'
import {BingEntityFactory} from '@/entities/BingEntityFactory'
import {sortBy} from 'lodash'
const lookup = require('country-code-lookup')

export class Bing {
    constructor(bing, check, companyName, options) {
        this.setGPE(bing)
        this.setCountry(bing, options?.countries || [])
        this.companyName = companyName || null
        this.publishedAt = bing.datePublished
        this.setReleasedLongAgo()
        this.name = bing.name
        this.description = bing.description
        this.url = bing.url
        this.setResultInPercents(bing)
        this.providers = bing.provider
        if (check) {
            this.color = getColorBySector(webalize(check.area))
            this.sector = check.area
            this.setPath(check)
        }
        this.setEntities(bing)
    }

    setEntities(bing) {
        this.entities = sortBy(Object.keys(bing.extracted_features?.entities || {}).map(key => {
            return {
                key,
                upperKey: key.toUpperCase(),
                mappedKey: BingEntityFactory.createKey(key.toUpperCase()),
                value: bing.extracted_features.entities[key],
                joined: bing.extracted_features.entities[key].join(', '),
            }
        }), 'mappedKey') || []
    }

    setPath(check) {
        this.path = [check.area, check.sub_area, check.sub_sub_area]
        this.stringPath = `${check.area} / ${check.sub_area} / ${check.sub_sub_area}`
    }

    setResultInPercents(bing) {
        this.resultInPercents = parseFloat((bing.res_json * 100).toFixed(2))
    }

    setReleasedLongAgo() {
        this.releasedLongAgo = moment(this.publishedAt).isBefore(moment().subtract(9, 'months'))
    }

    setGPE(bing) {
        this.gpe = bing.extracted_features?.entities?.gpe || null
    }

    setCountry(bing, countries) {
        const {gpe} = this

        if (gpe) {
            let country

            for (let i = 0; i < gpe.length; i++) {
                let result = lookup.byCountry(gpe[i])
                if (!result) { result = lookup.byFips(gpe[i]) }
                if (!result) { result = lookup.byInternet(gpe[i]) }
                try { if (!result) { result = lookup.byIso(gpe[i]) } } catch (e) {}

                if (result) {
                    country = result
                    if (country.continent === 'Americas') {
                        country.continent = 'America'
                    }
                    continue
                }

                const resultByCapital = countries.find(c => c.capital === gpe[i] || c.capital?.toLowerCase() === gpe[i]?.toLowerCase())
                if (resultByCapital) {
                    const bySpelling = resultByCapital.altSpellings
                        .map(spelling => lookup.byInternet(spelling))
                        .filter(spelling => spelling)
                    if (bySpelling?.length) {
                        country = bySpelling[0]
                        continue
                    }
                }
                // console.log('NOT FOUND', gpe[i])
            }

            this.country = country
        }
    }
}
