<template>
  <v-card :class="{'card-shadow': showCard}" class="border-radius-xl z-index-2">
    <div :class="{'card-padding': showCard}">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height || 250"></canvas>
      </div>
    </div>
  </v-card>
</template>
<script>
import Chart from 'chart.js/auto'
import {
  ENVIRONMENT_COLOR,
  ENVIRONMENT_GRADIENT, getChartGradientBySector, getColorBySector,
  getTooltipPercentFormatted, GOVERNANCE_COLOR,
  GOVERNANCE_GRADIENT, SOCIAL_COLOR,
  SOCIAL_GRADIENT
} from '@/util/charts'
import {faker} from '@faker-js/faker'

export default {
  name: 'esg-line-chart',
  props: {
    showCard: Boolean,
    id: String,
    height: Number,
    datasets: Array,
    labels: Array,
  },
  data: function () {
    return {
      chart: null
    }
  },

  mounted() {
    console.log(this.id)
    this.redraw()
  },

  methods: {
    redraw() {
      const element = document.getElementById(this.id)

      if (!element) {
        console.warn(`LineChart: redraw => Cannot get getElementById("${this.id}")`)
        return null
      }

      const ctx = element.getContext('2d')

      if (!ctx) {
        console.warn('LineChart: redraw => Context not found')
        return null
      }

      const environmentGradient = ENVIRONMENT_GRADIENT(ctx)
      const socialGradient = SOCIAL_GRADIENT(ctx)
      const governanceGradient = GOVERNANCE_GRADIENT(ctx)
      const tooltipFormatter = getTooltipPercentFormatted()

      if (this.chart) {
        this.chart.destroy()
      }

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.labels || ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: this.datasets?.length ? this.datasets.map(i => ({
            ...i,
            color: getColorBySector(i.key),
            backgroundColor: getChartGradientBySector(i.key, ctx)
          })) : [
            {
              label: 'Environment',
              tension: 0.4,
              borderWidth: 3,
              pointRadius: 0,
              borderColor: ENVIRONMENT_COLOR,
              backgroundColor: environmentGradient,
              fill: true,
              data: Array.from(Array(9).keys()).map(i => faker.datatype.number({min: 30, max: 75})),
              tooltip: tooltipFormatter,
              maxBarThickness: 6,
            },
            {
              label: 'Social',
              tension: 0.4,
              borderWidth: 3,
              pointRadius: 0,
              borderColor: SOCIAL_COLOR,
              backgroundColor: socialGradient,
              fill: true,
              data: Array.from(Array(9).keys()).map(i => faker.datatype.number({min: 30, max: 75})),
              tooltip: tooltipFormatter,
              maxBarThickness: 6,
            },
            {
              label: 'Governance',
              tension: 0.4,
              borderWidth: 3,
              pointRadius: 0,
              borderColor: GOVERNANCE_COLOR,
              backgroundColor: governanceGradient,
              fill: true,
              data: Array.from(Array(9).keys()).map(i => faker.datatype.number({min: 30, max: 75})),
              tooltip: tooltipFormatter,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#b2b9bf",
                font: {
                  size: 11,
                  family: 'Open Sans',
                  style: 'normal',
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: '#b2b9bf',
                padding: 10,
                font: {
                  size: 11,
                  family: 'Open Sans',
                  style: 'normal',
                  lineHeight: 2,
                },
              },
            },
          },
        },
      })
    }
  },
}
</script>
