
export class BingEntityFactory {
    static createKey(type) {
        const map = {
            CARDINAL: 'Numbers',
            DATE: 'Dates',
            EVENT: 'Events',
            FAC: 'Facilities',
            GPE: 'Geo Entities',
            LANGUAGE: 'Languages',
            LAW: 'Related',
            LOC: 'Places',
            MONEY: 'Money',
            NOR: 'Groups',
            ORDINAL: 'Amounts',
            ORG: 'Organisations',
            PERCENT: 'Percents',
            PERSON: 'Persons',
            PRODUCT: 'Products',
            QUANTITY: 'Quantities',
            TIME: 'Times',
            WORK_OF_ART: 'Artworks',
        }
        if ((map[type] || type) === type) {
            // console.debug(`Not supported mapping for key [${type}]`)
        }
        return map[type] || type
    }
}
