<template>
  <div v-if="bings">
    <v-simple-table id="bings-table" v-if="bings.length" dense>
      <template v-slot:default>
        <template v-for="bing in bings">
          <tr :key="bing._id">
            <th class="pr-3 py-2 align-top text-left">
              <div v-if="showCompany">{{ bing.companyName }}</div>
              <div class="d-flex align-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="me-2" v-bind="attrs" v-on="on" :style="{background: bing.color}"
                          style="display: inline-block; border-radius: 50%; width: 8px; height: 8px;"></span>
                  </template>
                  <span>{{ bing.sector }}</span>
                </v-tooltip>
                <span class="text-xs text-danger">{{ bing.resultInPercents }}%</span>
              </div>
              <div class="text-xxs text--darken-3 mt-1" style="white-space: nowrap;">
                  {{toDate(bing.publishedAt)}}
              </div>
              <div v-if="bing.country" class="d-flex align-center mt-1">
                <div class="text-xxs text--darken-3 me-2">{{ bing.country.country }}</div>
                <img :src="'https://countryflagsapi.com/png/' + bing.country.iso2" height="13">
              </div>
            </th>
            <td class="py-2 align-top">
              <div class="text-xxs text-hypo">{{ bing.stringPath }}</div>
              <span v-for="provider of bing.providers"
                    class="text-sm text-hypo font-weight-bold">{{ provider.name }}: </span>
              <a :href="bing.url" target="_blank" class="text-sm text-typo">{{ bing.name }}</a>
              <div class="text-xs">{{ bing.description }}</div>

              <div v-if="bing.entities.length" class="mt-3">
                <template v-for="entity in bing.entities">
                  <v-chip v-if="entity.value.length" small outlined class="me-2 mb-2">
                    <span>{{ entity.mappedKey }}:</span>&nbsp;<strong>{{ entity.joined }}</strong>
                  </v-chip>
                </template>
              </div>
              <div>
                <v-btn
                  v-if="!bing.checkResult"
                  @click="checkBing($event, bing)"
                  :disabled="bing.checkPending"
                  color="green"
                  class="text-capitalize ms-auto btn-primary bg-gradient-primary text-xs px-2 text-decoration-none"
                >
                  <v-progress-circular v-if="bing.checkPending" :size="20" indeterminate class="me-2"></v-progress-circular>
                  Check article
                </v-btn>
                <span v-if="bing.checkResult" class="text-typo text-xs py-1 d-inline-block">{{ bing.checkResult }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <v-divider class="py-2"></v-divider>
            </td>
          </tr>
        </template>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'articles-table',

  props: {
    articles: {
        type: Array
    },
    showCompany: Boolean
  },

  watch: {
    $props: {
      handler() {
        this.bings = [...this.articles]
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
        bings: []
    }
  },

  methods: {
    toDate(date) {
      return moment(date).format('ddd, D. MMMM, YYYY')
    },

    async checkBing(event, bing) {
      bing.checkPending = true
      this.bings = [...this.bings]
      try {
        const res = await this.$store.getters.request(
      `esg-plausibility-check/check-bing`,
  'POST',
    {companyName: bing.companyName, bingUrl: bing.url}
        )
        bing.checkResult = res.data?.length ? res.data : 'No information found'
        bing.checkPending = false
      } catch (e) {
          bing.checkResult = 'No information found'
        bing.checkPending = false
      }
      this.bings = [...this.bings]
    }
  }
}
</script>

<style></style>

